import { useState } from 'react';
import authoritiesService from '../../services/authorities.service';

interface IState {
  loading: boolean;
  result: { authorities: Array<any>, count: number } | null;
}

const useListAuthorities = () => {
  const [state, setState] = useState<IState>({ loading: false, result: null });

  const listAuthorities = (collectionId: string, type: string) => {
    setState({ loading: true, result: null });

    authoritiesService.listAuthorities(collectionId, type)
      .then(result => setState({ loading: false, result }))
      .catch((e) => {
        console.error(e);
        setState({ ...state, loading: false });
      })
  }

  return {
    ...state,
    listAuthorities
  };
};

export default useListAuthorities;
