import { Cluster } from "../../../types/cluster";
import Chip from "../../shared/chip/chip.component";
import classes from "./cluster-item.module.sass";

const ClusterItem = (props: Cluster.ICandidate) => {
  const {
    name,
    id,
    type,
    original,
    terms,
    isPrimary,
    address,
    patternName,
    bondLabel,
    collectionId,
    docType,
  } = props;
  const dis = `/cluster/${type}/?id=${id}&collectionId=${collectionId}`;

  return (
    <div className={classes.container}>
      <nav>
        <div>
          {!!original && <h2>Original name: {original}</h2>}
          <h2>Name: {name}</h2>
          <p>
            Id:{" "}
            <a href={dis} target="_blank" rel="noreferrer">
              {id}
            </a>
          </p>
          {!!patternName && <p>Pattern name: {patternName}</p>}
          {!!terms && (
            <div>
              <span>Terms: </span>
              {terms?.map((t: string) => (
                <Chip text={t} key={t} />
              ))}
            </div>
          )}
          <div className={classes.inline}>
            Type: <Chip>{type}</Chip>
          </div>
          {docType !== 'CLUSTER' && (
            <div className={classes.inline}>
              Match level:{" "}
              {isPrimary
                ? (<Chip className={classes.inline}>Primery</Chip>)
                : (<Chip className={classes.inline}>Not primery</Chip>)
              }
            </div>
          )}
          <div className={classes.inline}>
            Bond label: <Chip>{bondLabel}</Chip>
          </div>
          {!!address && (
            <div className={classes.inline}>
              <span>Address: </span>
              {!!address?.country && (
                <span>
                  <span>Country: </span>
                  <Chip text={address.country} />
                </span>
              )}{" "}
              {!!address?.state && (
                <span>
                  <span>State: </span>
                  <Chip text={address.state} />
                </span>
              )}{" "}
              {!!address?.city && (
                <span>
                  <span>City: </span>
                  <Chip text={address.city} />
                </span>
              )}
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default ClusterItem;
