import Pie from "./charts/pie/pie.component";
import DetailedTable from "./detailed-table/detailed-table.component";

import classes from "./evaluation-view.module.sass";
import Statistics from "../../../types/statistics";

import useReport from "../../../hooks/evaluation/report.hook";
import useViewer from "../../../hooks/viewer/viewer.hook";
import { useEffect, useState } from "react";
import useDetailedReport from "../../../hooks/evaluation/detailed-report";

const EvaluationView = () => {
  const { loading, result: report, getReportByCollectionId } = useReport();
  const { result: viewerResult, listFinalDocumentCollections } = useViewer();
  const { loading: loadingDetailedReport, result, getDetailedReport } = useDetailedReport();
  const [random, setRandom] = useState(false);

  const [collectionId, setCollectionId] = useState("");
  const institutionsSum = report?.institutionsSum || 0;
  const affCount = report?.affCount || 0;
  const overlapInstitutionCount = report?.overlapInstitutionCount || 0;
  const mismatchAffCount = report?.mismatchAffCount || 0;
  const sadaAffCount = report?.sadaAffCount || 0;
  const nonSadaAffCount = report?.nonSadaAffCount || 0;
  const sadaInstitutionsCount = report?.sadaInstitutionsCount || 0;
  const oldInstitutionsCount = report?.oldInstitutionsCount || 0;
  const sadaOnlyInstitutionsCount = report?.sadaOnlyInstitutionsCount || 0;
  const sadaOnlyAffCount = report?.sadaOnlyAffCount || 0;
  const nonSadaOnlyInstitutionsCount =
    report?.nonSadaOnlyInstitutionsCount || 0;
  const nonSadaOnlyAffCount = report?.nonSadaOnlyAffCount || 0;
  const noneCount = report?.noneCount || 0;
  const overlapLeafAffCount = report?.overlapLeafAffCount || 0;
  const overlapParentAffCount = report?.overlapParentAffCount || 0;
  const matchDiffLevelsAffCount = report?.matchDiffLevelsAffCount || 0;

  const oldInstitutionsAffiliationCoverage: Statistics.IRow[] = [
    {
      id: "none",
      label: "none",
      value: noneCount + sadaOnlyAffCount,
    },
    {
      id: "matches",
      label: "Matches",
      value: nonSadaAffCount,
    },
  ];

  const sadaAffiliationCoverage: Statistics.IRow[] = [
    {
      id: "none",
      label: "none",
      value: noneCount + nonSadaOnlyAffCount,
    },
    {
      id: "matches",
      label: "Matches",
      value: sadaAffCount,
    },
  ];

  const affiliationDistribution: Statistics.IRow[] = [
    {
      id: "none",
      label: "none",
      value: noneCount,
    },
    {
      id: "oldInstitutionsOnly",
      label: "OldInstitutionsOnly",
      value: nonSadaOnlyAffCount,
    },
    {
      id: "sadaOnly",
      label: "SadaOnly",
      value: sadaOnlyAffCount,
    },
    {
      id: "overlapMatches",
      label: "Overlap Matches",
      value: overlapLeafAffCount,
    },
  ];

  useEffect(() => {
    listFinalDocumentCollections();
  }, []);

  useEffect(() => {
    getReportByCollectionId(collectionId);
  }, [collectionId]);

  return (
    <div>
      <div className={classes.wrapper}>
        <select
          onChange={(e) => {
            setCollectionId(e.target.value);
          }}
        >
          <option value="">Choose dataset</option>
          {viewerResult?.map((collection) => {
            return (
              <option value={collection.collectionId} key={collection.collectionId}>
                {collection.label}
              </option>
            );
          })}
        </select>
      </div>
      {!loading && !!report && (
        <>
          <table className={classes.table}>
            <thead>
              <tr>
                <th></th>
                <th>Institution Ids #</th>
                <th>Affiliation #</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Sada</td>
                <td>{sadaInstitutionsCount}</td>
                <td>{sadaAffCount}</td>
              </tr>
              <tr>
                <td>Inspec</td>
                <td>{oldInstitutionsCount}</td>
                <td>{nonSadaAffCount}</td>
              </tr>
              <tr>
                <td>Overlap Matches</td>
                <td>{overlapInstitutionCount}</td>
                <td>{overlapLeafAffCount + overlapParentAffCount}</td>
              </tr>
              <tr>
                <td>Match at Different Levels</td>
                <td>NA</td>
                <td>{matchDiffLevelsAffCount}</td>
              </tr>
              <tr>
                <td>Sada Only</td>
                <td>{sadaOnlyInstitutionsCount}</td>
                <td>{sadaOnlyAffCount}</td>
              </tr>
              <tr>
                <td>Old Institutions Only</td>
                <td>{nonSadaOnlyInstitutionsCount}</td>
                <td>{nonSadaOnlyAffCount}</td>
              </tr>
              <tr>
                <td>Mismatch</td>
                <td>NA</td>
                <td>{mismatchAffCount}</td>
              </tr>
              <tr>
                <td>None</td>
                <td>NA</td>
                <td>{noneCount}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>{institutionsSum}</td>
                <td>{affCount}</td>
              </tr>
            </tbody>
          </table>
          <div style={{ height: "400px", width: "100%", display: "flex" }}>
            <Pie data={oldInstitutionsAffiliationCoverage} />
            <Pie data={sadaAffiliationCoverage} />
            <Pie data={affiliationDistribution} />
          </div>
          <DetailedTable loading={loadingDetailedReport} result={result} getDetailedReport={getDetailedReport} collectionId={collectionId} />
        </>
      )}
      {!loadingDetailedReport && collectionId && (
        <div className={classes.actionsWrapper}>
          {(!result || random) && <button
            className={classes.loadButton}
            onClick={(e) => { getDetailedReport(collectionId); setRandom(false) }}
          >
            Load Detailed Data
          </button>
          }
          {<button
            className={classes.loadButton}
            onClick={(e) => { getDetailedReport(collectionId, true); setRandom(true) }}
          >
            Load Random 1000 samples
          </button>
          }
        </div>
      )}
      {loading && <div>loading...</div>}
    </div>
  );
};

export default EvaluationView;
