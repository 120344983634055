import error from './../errors/http-error';
class AuhoritiesService {

  listAuthoritiesCollections = (type: string): Promise<any> => {
    return fetch(`/api/authorities/collections?type=${type}`)
      .then(error.throwHttpError)
      .then((res) => res.json());
  }

  listAuthorities = (collectionId: string, type: string): Promise<any> => {
    return fetch(`/api/authorities/list?collectionId=${collectionId}&type=${type}`)
      .then(error.throwHttpError)
      .then((res) => res.json());
  }
}

export default new AuhoritiesService();
