import error from './../errors/http-error';
class EvaluationService {
    getReport = (collectionId: string): Promise<any> => {

        return fetch(`/api/evaluation?collectionId=${collectionId}`)
            .then(error.throwHttpError)
            .then((res) => res.json());
    }

    getDetailedReport = (collectionId: string, random = false): Promise<any> => {

        return fetch(`/api/evaluation/detailed-report?collectionId=${collectionId}&random=${random}`)
            .then(error.throwHttpError)
            .then((res) => res.json());
    }
}

export default new EvaluationService();