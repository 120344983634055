import { useState } from 'react';
import evaluationService from '../../services/evaluation.service';
import { Evaluation } from '../../types/evaluation';

interface IState {
    loading: boolean;
    result: Evaluation.IReport | null;
}

const useReport = () => {
    const [state, setState] = useState<IState>({ loading: false, result: null });

    const getReportByCollectionId = (collectionId: string = '') => {
        if(!collectionId) {
            setState({ loading: false, result: null });
            return;
        }

        setState({ loading: true, result: null });

        evaluationService.getReport(collectionId)
            .then(result => setState({ loading: false, result }))
            .catch((e) => {
                console.error(e);
                setState({ ...state, loading: false });
            })
    }

    return {
        ...state,
        getReportByCollectionId
    }
};


export default useReport;