import error from './../errors/http-error';
class ClusterService {

  list = (collectionId: string): Promise<any> => {
    return fetch(`/api/cluster/list?collectionId=${collectionId}`)
      .then(error.throwHttpError)
      .then((res) => res.json());
  }

  getCluster = (collectionId: string, id: string, type: string, page: number): Promise<any> => {
    return fetch(`/api/cluster?collectionId=${collectionId}&type=${type}&id=${id}&page=${page}`)
      .then(error.throwHttpError)
      .then((res) => res.json());
  };

  listFinalDocumentCollections = (): Promise<any> => {
    return fetch(`/api/finalDocumentCollections/clusters`)
      .then(error.throwHttpError)
      .then((res) => res.json());
  }
}

export default new ClusterService();
