import { useState } from 'react';
import evaluationService from '../../services/evaluation.service';

interface IState {
    loading: boolean;
    result: any;
}

const useDetailedReport = () => {
    const [state, setState] = useState<IState>({ loading: false, result: null });

    const getDetailedReport = (collectionId: string = '', random?: boolean) => {
        setState({ loading: true, result: null });

        evaluationService.getDetailedReport(collectionId, random)
            .then(result => setState({ loading: false, result }))
            .catch((e) => {
                console.error(e);
                setState({ ...state, loading: false });
            })
    }

    return {
        ...state,
        getDetailedReport
    }
};


export default useDetailedReport;