import classNames from 'classnames';
import classes from './chip.module.sass'

interface IProps {
    text?: string,
    color?: string;
    className?: string;
    children?: any;
}

const Chip = (props: IProps) => {
    return (
        <div className={classNames(classes.chip, props.color && classes[props.color], props.className)}>
            {props.text}
            {props.children}
        </div>
    )
}

export default Chip;