import classes from "./html-cell.module.sass";

interface IProps {
  children: JSX.Element | JSX.Element[];
}

const HtmlCell = (props: IProps) => {
  return <div className={classes.wrapper}>{props.children}</div>;
};

export default HtmlCell;
