import React from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import Home from './pages/home.page';
import Cluster from './pages/cluster.page';
import Header from './components/layout/header/header.component';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/cluster/:type' element={<Cluster/>} />
      </Routes>
    </Router>
  );
}

export default App;
