import DataTable from 'react-data-table-component';
import Chip from '../../../shared/chip/chip.component';
import HtmlCell from '../../../shared/html-cell/html-cell.component';
import { useEffect } from 'react';
import useListAuthorities from '../../../../hooks/viewer/list-authorities.hook';

const cellStyle = { borderRight: '1px solid #e7e7e7' };

interface IProps {
  collectionId: string;
  type: string;
}

const AuthoritiesTable = (props: IProps) => {
  const { loading, result, listAuthorities } = useListAuthorities();
  const authorities = result?.authorities || [];
  const collectionId = props.collectionId;
  const type = props.type;

  useEffect(() => {
    listAuthorities(collectionId, type);
  }, [collectionId]);

  const columns: any[] = [
    {
      name: 'Authority Name',
      selector: (row: any) => (
        <HtmlCell>
          <span>{row?.name}</span>
        </HtmlCell>
      ),
      style: cellStyle,
    },
    {
      name: 'Authority Id',
      selector: (row: any) =>
        (<Chip text={row.id} />) || '',
      style: cellStyle,
    },
    {
      name: 'Terms',
      selector: (row: any) =>
        <span>
          {row?.terms?.map((t: string) => (
            <Chip text={t} />
          ))}
        </span>,
      style: cellStyle,
    },
    {
      name: 'Country',
      selector: (row: any) => <Chip text={row?.address?.country} /> || '',
      maxWidth: '50px',
      style: cellStyle,
    },
    {
      name: 'State',
      selector: (row: any) =>
        row?.address?.state ? <Chip text={row?.address.state} /> : '',
      maxWidth: '50px',
      style: cellStyle,
    },
    {
      name: 'City',
      selector: (row: any) =>
        row?.address?.city ? <Chip text={row?.address.city} /> : '',
      maxWidth: '100px',
      style: cellStyle,
    },
  ];

  return (
    <div>
      {loading && (
        <div>
          <h3>loading...</h3>
        </div>
      )}
      {!loading && result && (
        <DataTable
          columns={columns}
          data={authorities}
          pagination={true}
          subHeaderWrap
        />
      )}
    </div>
  );
};

export default AuthoritiesTable;
