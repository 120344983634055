import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import PageLayout from "../components/layout/page-layout/page-layout.component";
import EvaluationView from "../components/page-components/evaluation-view/evaluation-view.component";
import UnmatchedClusterView from "../components/page-components/unmatched-cluster-view/unmatched-cluster-view.component";
import AuthroitiesView from "../components/page-components/authorities-view/authorities-view.component";

const Home = () => {
  return (
    <PageLayout>
      <div>
        <Tabs>
          <TabList>
            <Tab>Evaluation Viewer</Tab>
            <Tab>Unmatched Clusters</Tab>
            <Tab>Authorities</Tab>
          </TabList>
          <TabPanel>
            <EvaluationView />
          </TabPanel>
          <TabPanel>
            <UnmatchedClusterView />
          </TabPanel>
          <TabPanel>
            <AuthroitiesView />
          </TabPanel>
        </Tabs>
      </div>
    </PageLayout>
  );
};

export default Home;
