import React, { useState, useLayoutEffect, useRef } from "react";
import styled from "styled-components";

interface IVLW {
  children?: any;
  className?: any;
}

interface IW {
  lineHeight: number;
}

const Wrapper = styled.section`
  width: 100%;
  margin: 1.67% auto;
  position: relative;
  padding: 0em 0.8%;
	left: 22px;

  * {
    box-sizing: border-box;
  }

  ::after {
    content: "";
    display: table;
    clear: both;
  }

  ::before {
    content: "";
    position: absolute;
    top: -35px;
    left: 10px;
    height: ${(props: IW) => props.lineHeight}px;
    width: 2px;
    background: #cccccc;
  }
`;

function VerticalLineWrapper({ className, children }: IVLW) {
  const [lineHeight, setLineHeight] = useState<any>();
  const wrapperHeight = useRef<any>(null);

  useLayoutEffect(() => {
    const handleResize = () => {
      const clientHeight = wrapperHeight?.current?.clientHeight || 0;
      setLineHeight(clientHeight + 35);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [children, lineHeight]);

  return (
    <Wrapper ref={wrapperHeight} lineHeight={lineHeight} className={className}>
      {children}
    </Wrapper>
  );
}

export default VerticalLineWrapper;
