import ClusterTable from "./detailed-table/detailed-table.component";

import classes from "./unmatched-cluster-view.module.sass";

import useClusters from "../../../hooks/cluster/clusters.hook";
import { useEffect, useState } from "react";

const UnmatchedClusterView = () => {
  const { result: viewerResult, listFinalDocumentCollections } = useClusters();
  const [collectionId, setCollectionId] = useState("");

  useEffect(() => {
    listFinalDocumentCollections();
  }, []);

  return (
    <div>
      <div className={classes.wrapper}>
        <select
          onChange={(e) => {
            setCollectionId(e.target.value);
          }}
        >
          <option value="">Choose dataset</option>
          {viewerResult?.map((collection) => {
            return (
              <option value={collection.collectionId}>
                {collection.label}
              </option>
            );
          })}
        </select>
      </div>
      {!!collectionId && <ClusterTable collectionId={collectionId} />}
    </div>
  );
};

export default UnmatchedClusterView;
