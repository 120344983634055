import { useParams, useSearchParams } from "react-router-dom";
import "react-tabs/style/react-tabs.css";

import ClusterView from "../components/page-components/cluster-view/cluster-view.component";

const Home = () => {
  const { type } = useParams();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const collectionId = searchParams.get("collectionId");

  return (
    <div>
      <div>
        <ClusterView
          collectionId={collectionId}
          candidateType={type}
          candidateId={id}
        />
      </div>
    </div>
  );
};

export default Home;
