import React from "react";
import styled from "styled-components";

const Content = styled.aside`
  position: relative;
  margin: 1.67% auto;
  padding-left: 10px;

  ::after {
    content: "";
    display: table;
    clear: both;
  }

  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
`;

interface IContentLine {
  lineWidth?: any;
}

const ContentLine = styled.span`
  position: absolute;
  top: 50%;
  left: 0%;
  width: ${(props: IContentLine) => props.lineWidth}%;
  height: 2px;
  background-color: #cccc;
  display: block;
  margin-left: -6px;
`;

const ContentChildren = styled.div`
  position: relative;
  padding: 0em;
  margin-left: ${(props: IContentLine) => props.lineWidth}%;
`;

interface IVerticlaLine {
  children?: any;
  className?: any;
  hLineWidth?: any;
}

function VerticalLineContent({
  children,
  className,
  hLineWidth,
}: IVerticlaLine) {
  return (
    <Content className={className}>
      <ContentLine lineWidth={hLineWidth} />
      <ContentChildren lineWidth={hLineWidth - 1.67}>{children}</ContentChildren>
    </Content>
  );
}

export default VerticalLineContent;
