import { useState } from 'react';
import clusterService from '../../services/cluster.service';

interface IState {
  loading: boolean;
  result: Array<any> | null;
}

const useClusters = () => {
  const [state, setState] = useState<IState>({ loading: false, result: null });

  const listFinalDocumentCollections = () => {
    setState({ loading: true, result: null });

    clusterService.listFinalDocumentCollections()
      .then(result => setState({ loading: false, result }))
      .catch((e) => {
        console.error(e);
        setState({ ...state, loading: false });
      });
  }

  return {
    ...state,
    listFinalDocumentCollections
  };
};

export default useClusters;
