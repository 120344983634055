import error from './../errors/http-error';
class ViewerService {
  listFinalDocumentCollections = (): Promise<any> => {

    return fetch(`/api/finalDocumentCollections`)
      .then(error.throwHttpError)
      .then((res) => res.json());
  }
}

export default new ViewerService();
