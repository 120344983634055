import DataTable from "react-data-table-component";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import useDetailedReport from "../../../../hooks/evaluation/detailed-report";
import Chip from "../../../shared/chip/chip.component";
import classes from "./detailed-table.module.sass";
import HtmlCell from "../../../shared/html-cell/html-cell.component";

const cellStyle = {
  borderRight: "1px solid #e7e7e7",
};

interface IProps {
  collectionId: string;
  loading: boolean;
  result: any;
  getDetailedReport: (collectionId: string) => void;
}

const DetailedTable = (props: IProps) => {
  const overlapMatches = props.result?.algResult.overlapMatch;
  const overlapDiffLevel = props.result?.algResult.overlapDiffLevel;
  const mismatch = props.result?.algResult.mismatch;
  const sadaOnly = props.result?.algResult.sadaOnly;
  const oldInstitutionResult = props.result?.oldInstitutionResult;
  const nonMatches = props.result?.noneResult;
  const collectionId = props.collectionId;

  const columns: any[] = [
    {
      name: "Sada Institution",
      selector: (row: any) => (
        <HtmlCell>
          <span>{row?.sadaInstitutionId}</span>
          <hr />
          <span>{row?.sadaInstitutionName}</span>
        </HtmlCell>
      ),
      style: cellStyle,
    },
    {
      name: "Sada Level Matches",
      selector: (row: any) =>
        row?.patterns
          ?.map((p: any) => p.institutionId)
          .map((id: string) => (
            <a
              href={`/cluster/institution/?id=${id}&collectionId=${collectionId}`}
              target="_blank"
              rel="noreferrer"
              key={id}
            >
              <Chip
                text={id}
                color={id === row.oldInstitutionId ? "green" : ""}
              />
            </a>
          )) || "",
      style: cellStyle,
    },
    {
      name: "Non Sada institution",
      selector: (row: any) =>
        (
          <HtmlCell>
            <span>{row?.oldInstitutionId}</span>
            <hr />
            <span>{row?.oldInstitutionName}</span>
          </HtmlCell>
        ) || "",
      wrap: true,
      style: cellStyle,
    },
    {
      name: "Name",
      selector: (row: any) =>
        (
          <HtmlCell>
            <span>{row?.affiliation?.name}</span>
            <hr />
            <span>
              {row?.terms?.map((t: string) => (
                <Chip text={t} key={t} />
              ))}
            </span>
          </HtmlCell>
        ) || "",
      minWidth: "300px",
      style: cellStyle,
    },
    {
      name: "Country",
      selector: (row: any) => <Chip text={row?.address?.country} /> || "",
      maxWidth: "50px",
      style: cellStyle,
    },
    {
      name: "State",
      selector: (row: any) =>
        row?.address?.state ? <Chip text={row?.address.state} /> : "",
      maxWidth: "50px",
      style: cellStyle,
    },
    {
      name: "City",
      selector: (row: any) =>
        row?.address?.city ? <Chip text={row?.address.city} /> : "",
      maxWidth: "100px",
      style: cellStyle,
    },
  ];

  return (
    <div>
      {props.loading && (
        <div>
          <h3>loading...</h3>
        </div>
      )}
      {!props.loading && props.result && (
        <Tabs>
          <TabList>
            <Tab>Overlap Matches</Tab>
            <Tab>Different Levels matches</Tab>
            <Tab>Mismatch</Tab>
            <Tab>Sada Only Matches</Tab>
            <Tab>Old Institution Matches</Tab>
            <Tab>None</Tab>
          </TabList>
          <TabPanel>
            <DataTable
              columns={columns}
              data={overlapMatches}
              pagination={true}
              subHeaderWrap
            />
          </TabPanel>
          <TabPanel>
            <DataTable
              columns={columns}
              data={overlapDiffLevel}
              pagination={true}
              subHeaderWrap
            />
          </TabPanel>
          <TabPanel>
            <DataTable
              columns={columns}
              data={mismatch}
              pagination={true}
              subHeaderWrap
            />
          </TabPanel>
          <TabPanel>
            <DataTable
              columns={columns}
              data={sadaOnly}
              pagination={true}
              subHeaderWrap
            />
          </TabPanel>
          <TabPanel>
            <DataTable
              columns={columns}
              data={oldInstitutionResult}
              pagination={true}
              subHeaderWrap
            />
          </TabPanel>
          <TabPanel>
            <DataTable
              columns={columns}
              data={nonMatches}
              pagination={true}
              subHeaderWrap
            />
          </TabPanel>
        </Tabs>
      )}
    </div>
  );
};

export default DetailedTable;
