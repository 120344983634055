import { useState } from 'react';
import clusterService from '../../services/cluster.service';
import { Cluster } from '../../types/cluster';

interface IState {
    loading: boolean;
    result: Cluster.ICluster | null;
    page: number
}

const useCluster = () => {
    const [state, setState] = useState<IState>({ loading: false, result: null, page: 1 });

    const getCluster = (collectionId?: string | null, id?: string | null, type?: string) => {
        if (!id || !collectionId) {
            setState({ ...state, loading: false, page: 1 });
            return;
        }

        setState({ ...state, loading: true });

        type = type || 'institution';
        clusterService.getCluster(collectionId, id, type, state.page || 1)
            .then(result => {
                result.cluster = (state.result?.cluster || []).concat(result?.cluster || []);
                setState({ ...state, loading: false, result, page: +result.page + 1 })
            }).catch((e) => {
                console.error(e);
                setState({ ...state, loading: false });
            });
    }

    return {
        ...state,
        getCluster
    }
};

export default useCluster;
