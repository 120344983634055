import { useState } from 'react';
import authoritiesService from '../../services/authorities.service';

interface IState {
  loading: boolean;
  result: { authorities: Array<{ collectionId: string, type: string, label: string }>, count: number } | null;
}

const useAuthorities = () => {
  const [state, setState] = useState<IState>({ loading: false, result: null });

  const listAuthoritiesCollections = (type: string) => {
    setState({ loading: true, result: null });

    authoritiesService.listAuthoritiesCollections(type)
      .then(result => setState({ loading: false, result }))
      .catch((e) => {
        console.error(e);
        setState({ ...state, loading: false });
      })
  }

  return {
    ...state,
    listAuthoritiesCollections
  };
};

export default useAuthorities;
