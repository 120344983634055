import AuthoritiesTable from './detailed-table/detailed-table.component';
import classes from './authorities-view.module.sass';
import { useEffect, useState } from 'react';
import useAuthorities from '../../../hooks/viewer/authorities.hook';

const authoritiesTypes: Array<{ type: string, label: string }> = [
  {
    type: 'AFFILIATION',
    label: 'Affiliation'
  },
  {
    type: 'INSTITUTION',
    label: 'Institution'
  },
  {
    type: 'INSTITUTION_CLUSTER',
    label: 'Institution cluster'
  }
];

const AuthoritiesView = () => {
  const [authoritiesType, setAuthoritiesType] = useState('');
  const [type, setType] = useState('');
  const { result: viewerResult, listAuthoritiesCollections } = useAuthorities();
  const [collectionId, setCollectionId] = useState('');
  const authorities = viewerResult?.authorities;
  const types = new Map<string, string>();

  if (!!authorities) {
    for (const authority of authorities) {
      types.set(authority.collectionId, authority.type);
    }
  }

  const list = (type: string) => {
    setAuthoritiesType(type);

    if (!!type) {
      setCollectionId('');
      listAuthoritiesCollections(type);
    }
  }

  useEffect(() => list(type), [type]);

  return (
    <div>
      <div>
        <div className={classes.wrapper}>
          <div className={classes.wrapper}>
            <select onChange={(e) => setType(e.target.value)}>
              <option value=''>Choose dataset</option>
              {authoritiesTypes?.map((collection) => (
                <option value={collection.type}>
                  {collection.label}
                </option>
              ))}
            </select>
          </div>
          {!!authoritiesType && <div className={classes.wrapper}>
            <select onChange={(e) => setCollectionId(e.target.value)}  >
              <option value=''>Choose dataset</option>
              {authorities?.map((collection) => (
                <option key={collection.collectionId} value={collection.collectionId}>
                  {collection.label}
                </option>
              ))}
            </select>
          </div>}
        </div>
        {!!collectionId && <div className={classes.collectionIdWrapper}>
          <p>CollectionId: {collectionId}</p>
        </div>}
      </div>
      {!!collectionId && <AuthoritiesTable collectionId={collectionId} type={types.get(collectionId) || ''} />}
    </div>
  );
};

export default AuthoritiesView;
