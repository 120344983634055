import classes from './header.module.sass';

const Header = () => {
    return (
        <header className={classes.header}>
            <nav>
                <div>
                    <svg height="40px" viewBox="0 0 512 512" width="40px"><g id="_x34_2-Bit_Connect"><g><g><path d="M167.407,186.342c0-19.07,0-37.77,0-56.114c0-1.439-2.114-3.593-3.9-4.702     c-12.123-8.613-18.504-20.482-16.731-35.617c1.767-15.474,9.954-26.258,24.573-31.646c17.805-6.454,38.095,2.16,45.925,19.444     c8.188,17.62,1.812,38.493-14.569,48.215c-3.205,1.765-4.297,3.584-3.949,7.181c0,15.832,0,31.656-0.351,47.848     c0,2.527,0,6.133,0,6.133s3.587,0.72,5.7,1.076c6.433,1.061,12.827,3.219,18.868,5.729c2.151,0.715,3.586,1.811,3.586,1.811     s2.857-2.494,3.553-2.86c28.841-24.864,57.704-49.664,86.555-74.477c12.135-10.433,24.559-21.222,36.69-31.657     c2.135-1.829,3.215-3.245,2.485-6.519c-3.95-17.59,0.728-33.071,14.939-44.217c13.934-11.142,29.261-12.952,45.284-5.398     c16.025,7.58,24.22,21.239,24.558,38.83c0.692,30.978-31.354,52.89-59.51,40.688c-3.19-1.452-4.955-0.725-7.437,1.075     c-38.854,32.007-77.677,63.664-116.862,95.346c-1.418,1.413-2.847,2.502-4.594,3.936     c46.628,39.208,53.416,109.023,14.913,157.917c11.043,13.319,22.456,27.021,33.859,39.928c1.042,1.117,3.553,1.117,5.337,1.117     c19.961-3.645,39.188,8.974,44.193,28.788c4.967,20.118-6.805,40.636-26.379,46.034c-14.619,4.295-27.797,0.709-38.143-10.088     c-10.68-10.774-13.165-24.104-8.869-38.863c0.694-2.88,0.694-4.647-1.056-7.178c-9.282-11.134-18.509-22.675-27.461-34.174     c-1.407-1.469-2.484-2.89-3.89-4.711c-62.365,41.413-141.083,11.554-164.954-51.067c-13.165-33.82-11.014-66.898,7.487-98.573     C105.092,209.014,132.854,191.737,167.407,186.342L167.407,186.342z" style={{fill: '#5a1088'}}></path></g></g></g><g id="Layer_1"></g></svg>
                    <h1>Institution Matching</h1>
                </div>
            </nav>
        </header>
    )
};

export default Header;