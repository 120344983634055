import { useState } from 'react';
import clusterService from '../../services/cluster.service';

interface IState {
    loading: boolean;
    result: any;
}

const useUnmatchedCluster = () => {
    const [state, setState] = useState<IState>({ loading: false, result: null });

    const list = (collectionId: string = '') => {
        setState({ loading: true, result: null });

        clusterService.list(collectionId)
            .then(result => setState({ loading: false, result }))
            .catch((e) => {
                console.error(e);
                setState({ ...state, loading: false });
            });
    }

    return {
        ...state,
        list
    };
};

export default useUnmatchedCluster;
