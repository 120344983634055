import classes from "./cluster-view.module.sass";
import Header from "../../layout/cluster-header/cluster-header.components";
import ClusterItem from "../../layout/cluster-item/cluster-item.components";
import VerticalLineContent from "../../layout/vertical-line/vertical-line.components";
import InfiniteScroll from "react-infinite-scroll-component";
import VerticalLineWrapper from "../../layout/vertical-line/vertical-line-wrapper.components";

import useCluster from "../../../hooks/cluster/cluster.hook";
import { useEffect } from "react";


interface IProps {
  candidateId?: string | null;
  candidateType?: string;
  collectionId?: string | null;
}

const ClusterView = (props: IProps) => {
  const { candidateType, candidateId, collectionId } = props;
  const { result: cluster, getCluster } = useCluster();
  const name = cluster?.candidate?.name;
  const id = cluster?.candidate?.id;
  const type = cluster?.candidate?.type;
  const original = cluster?.candidate?.original;
  const terms = cluster?.candidate?.terms;
  const address = cluster?.candidate?.address;
  const clusterItems = cluster?.cluster || [];
  const docType = cluster?.type;
  const next = cluster?.next ?? false;

  const updateCluster = () => {
    if (!cluster) {
      getCluster(collectionId, candidateId, candidateType);
    }
  };

  useEffect(updateCluster, []);

  return (
    <div className={classes.container}>
        <Header
          id={id}
          type={type}
          name={name}
          original={original}
          terms={terms}
          address={address}
        />
      <InfiniteScroll
        dataLength={clusterItems.length}
        next={() => getCluster(collectionId, candidateId, candidateType)}
        hasMore={next}
        loader={<h4>Loading...</h4>}
        style={{minHeight: '100vh'}}
      >
        <VerticalLineWrapper>
          {clusterItems?.map((item) => {
            const score = item.bondScore || 1;
            const hLineWidth = score * 10;
            return (
              <VerticalLineContent hLineWidth={hLineWidth} key={item.id}>
                <ClusterItem
                  id={item.id}
                  type={item.type}
                  name={item.name}
                  original={item.original}
                  patternName={item.patternName}
                  terms={item.terms}
                  address={item.address}
                  isPrimary={item.isPrimary}
                  bondLabel={item.bondLabel}
                  collectionId={collectionId || ""}
                  docType={docType}
                />
              </VerticalLineContent>
            );
          })}
        </VerticalLineWrapper>
      </InfiniteScroll>
    </div>
  );
};

export default ClusterView;
