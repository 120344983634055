import { Cluster } from "../../../types/cluster";
import Chip from "../../shared/chip/chip.component";
import classes from "./cluster-header.module.sass";

const Header = (props: Cluster.ICandidate) => {
  const { name, id, type, original, terms, address } = props;

  return (
    <header className={classes.header}>
      <nav>
        <div>
          {!!original && <h2>Original name: {original}</h2>}
          <h2>Name: {name}</h2>
          <p>Id: {id}</p>
          {!!terms && (
            <div>
              <span>Terms: </span>
              {terms?.map((t: string) => (
                <Chip text={t} key={t}/>
              ))}
            </div>
          )}
          <div className={classes.inline}>Type: <Chip>{type}</Chip></div>
          {!!address && (
            <div className={classes.inline}>
              <span>Address: </span>
              {!!address?.country && (
                <span>
                  <span>Country: </span>
                  <Chip text={address.country} />
                </span>
              )}{" "}
              {!!address?.state && (
                <span>
                  <span>State: </span>
                  <Chip text={address.state} />
                </span>
              )}{" "}
              {!!address?.city && (
                <span>
                  <span>City: </span>
                  <Chip text={address.city} />
                </span>
              )}
            </div>
          )}
        </div>
      </nav>
      <div className={classes.underLine}></div>
    </header>
  );
};

export default Header;
