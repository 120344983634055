import DataTable from "react-data-table-component";
import useUnmatchedCluster from "../../../../hooks/cluster/unmatched-cluster";
import Chip from "../../../shared/chip/chip.component";
import HtmlCell from "../../../shared/html-cell/html-cell.component";
import { useEffect } from "react";

const cellStyle = {
  borderRight: "1px solid #e7e7e7",
};

interface IProps {
  collectionId: string;
}

const ClusterTable = (props: IProps) => {
  const { loading, result, list } = useUnmatchedCluster();
  const cluster = result?.cluster;
  const collectionId = props.collectionId;

  useEffect(() => {
    list(collectionId);
  }, []);

  const columns: any[] = [
    {
      name: "Affiliation Name",
      selector: (row: any) => (
        <HtmlCell>
          <span>{row?.name}</span>
        </HtmlCell>
      ),
      style: cellStyle,
    },
    {
      name: "Affiliation Id",
      selector: (row: any) =>
        (
          <a
            href={`/cluster/candidate/?id=${row.id}&collectionId=${collectionId}`}
            target="_blank"
            rel="noreferrer"
          >
            <Chip text={row.id} />
          </a>
        ) || "",
      style: cellStyle,
    },
    {
      name: "Posting List Length",
      selector: (row: any) =>
        (
          <HtmlCell>
            <span>{row?.postingListLenght}</span>
          </HtmlCell>
        ) || "",
      wrap: true,
      style: cellStyle,
    },
  ];

  return (
    <div>
      {loading && (
        <div>
          <h3>loading...</h3>
        </div>
      )}
      {!loading && result && (
        <DataTable
          columns={columns}
          data={cluster}
          pagination={true}
          subHeaderWrap
        />
      )}
    </div>
  );
};

export default ClusterTable;
